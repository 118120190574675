.system-tab-main__title {
    color: #006DC0;
    font-size: 16px !important;
    line-height: 18px;
    font-weight: 800;
    margin-bottom: 10px;

    @media screen and (max-width: 1000px) {
        font-size: 24px !important;
    }
}

.system-tab-main__text {
    color: #455768;
    font-size: 16px !important;
    line-height: 18px;
    font-weight: 400;
    font-family: Rubik !important;

    @media screen and (max-width: 1000px) {
        font-size: 16px !important;
    }
}

.accordion__list {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
}

.accordion__list::-webkit-scrollbar-track {
    background-color: transparent;
    border: none;
    margin: 15px;
}

.accordion__list::-webkit-scrollbar {
    width: 5px;
    height: 50px;
}

.accordion__list::-webkit-scrollbar-thumb {
    margin: 15px;
    border-radius: 4px;
    background-color: #006DC0;
}

.activeSystem{
    font-weight: 800;
    font-size: 18px;
    color: #455768
}
.table__emptyContainer{
position: relative;
width: 61%;
height: 500px;

}
.table__noItem {
    position: absolute;
    top: 40%;
    left: 15%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    line-height: 18px;
    color: #6C7389;
}

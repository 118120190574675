.tabletViewMain {
  // background: #EEEEEE; //#BBBBBB;
  width: 100%;
  height: 100vh;
  z-index: 3 !important;
  background-color: #F9F9F9;
  // overflow-y: scroll;
}

.dataContainer {
  margin: 20px 50px;
  // height: 550px;
  height: 645px;
  // overflow-y: auto; 
}

// desktop
.tabletViewMain--desktop {
  max-width: 966px;
  height: 100vh;
  background-color: #F9F9F9;
  z-index: 3 !important;
  position: absolute;
  left: calc((100vw/2) - 480px);
}

// add order should algin more on left side
.addOderPopup__position{
  position: absolute;
  left: 0;
  // border: 2px solid red;
  width: 100%;
  // height: auto;
}


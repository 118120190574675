.detailsTab {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
    height: calc(100vh - 400px);
    // border: 1px solid red;
    overflow-y: scroll;
    padding-bottom: 16px;

    @media screen and (max-width: 600px) {
        height: 100vh;
        padding: 0 16px;
    }
}

.detailsTab__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
}

.detailsTab__row-inputs {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px !important;
}

.detailsTab__left {
    display: flex;
    justify-content: left;
    flex-direction: column;
    width: 538px;
    height: 100%;
    position: relative;

    @media screen and (max-width: 600px) {
        max-width: 100%;
        // max-width: 328px;
        height: 164px;
    }
}

.detailsTab__sign {
    width: 538px;
    height: 100%;
    // height: 192px;
    display: flex;
    flex-direction: column;
    justify-content: right;
    position: absolute;
    left: 0;
    bottom: 0;

    @media screen and (max-width: 600px) {
        width: 100%;
        height: 100%;
    }
}

.detailsTab__sign label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: right;
    font-size: 24px;
    line-height: 18px;
    font-weight: 800;
    color: #6C7389;
    margin: 20px 0;
    align-items: center;

    @media screen and (max-width: 600px) {
        font-size: 12px;
        margin: 10px 0;
    }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 16px !important;
        margin: 10px 0;
    }

}

.detailsTab__sign--desktop label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: right;
    font-size: 16px;
    line-height: 18px;
    font-weight: 800;
    color: #6C7389;
    margin: 20px 0;
    align-items: center;

    @media screen and (max-width: 600px) {
        font-size: 12px;
        margin: 10px 0;
    }

}

.detailsTab__sign-iconContainer {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: #F67288;

    @media screen and (max-width: 1000px) {
        width: 18px;
        height: 18px;
    }


}

.detailsTab__sign-iconContainer--desktop {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: #F67288;

    @media screen and (max-width: 1000px) {
        width: 18px;
        height: 18px;
    }

}

.detailsTab__sign-icon {
    // width: 27px;
    // height: 27px;
    // border-radius: 50%;
    font-size: 27px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #F67288;

    @media screen and (max-width: 1000px) {
        font-size: 18px !important;
    }
}

.detailsTab__signContainer {
    width: 100%;
    height: 192px;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #CACBCC;
    position: relative;
    border-radius: 6px;

    @media screen and (max-width: 600px) {
        height: 142px;
    }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        height: 142px !important;
    }
}

.detailsTab__signContainer--desktop {
    width: 100%;
    height: 142px;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #CACBCC;
    position: relative;
    border-radius: 6px;

    @media screen and (max-width: 600px) {
        height: 142px;
    }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        height: 142px !important;
    }
}

.detailsTab__sign__img {
    width: 100%;
    height: 142px;
    border: 1px solid #CACBCC;
    border-radius: 6px;
    box-shadow: 0px 0px 0px 0px #BABABA1A, 0px 1px 2px 0px #BABABA1A, 0px 4px 4px 0px #BABABA17, 0px 10px 6px 0px #BABABA0D, 0px 18px 7px 0px #BABABA03, 0px 28px 8px 0px #BABABA00;
}

.detailsTab__isSigned {
    display: flex;
    justify-content: center;
    font-size: 24px;
    line-height: 18px;
    font-weight: 500;
    color: #6C7389;
    margin-bottom: 12px;

    @media screen and (max-width: 1000px) {
        font-size: 12px;
        margin-bottom: 24px;
    }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 14px;
        margin-bottom: 24px;
    }
}

.detailsTab__isSigned--desktop {
    display: flex;
    justify-content: center;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #6C7389;
    margin-bottom: 12px;

    @media screen and (max-width: 600px) {
        font-size: 12px;
        margin-bottom: 24px;
    }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 14px;
        margin-bottom: 24px;
    }
}

.detailsTab__signBtn {
    width: calc(100% - 30px);
    position: absolute;
    bottom: 0px;
    right: 16px;
    margin-bottom: 12px;

    // margin: 12px 16px;
    @media screen and (max-width: 600px) {
        margin-bottom: 6px;
    }
}

.detailsTab__textArea {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    font-size: 24px;
    line-height: 18px;
    font-weight: 400;
    color: #6C7389;

    @media screen and (max-width: 600px) {
        margin: 10px 0;
    }
}

.detailsTab__textArea--desktop {
    display: flex;
    flex-direction: column;
    margin-top: 20px !important;
    margin-bottom: 40px !important;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color: #6C7389;

    @media screen and (max-width: 600px) {
        margin: 10px 0;
    }
}

.detailsTab__textArea label {
    font-size: 24px;
    line-height: 18px;
    font-weight: 800;
    color: #6C7389;
    margin-bottom: 10px;

    @media screen and (max-width: 600px) {
        font-size: 12px;
        margin-bottom: 10px;
    }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 16px;
        margin-bottom: 10px;
    }
}

.detailsTab__textArea--desktop label {
    font-size: 16px;
    line-height: 18px;
    font-weight: 800;
    color: #6C7389;
    margin-bottom: 10px;

    @media screen and (max-width: 600px) {
        font-size: 12px;
        margin-bottom: 10px;
    }
}

.detailsTab__textArea textarea {
    font-size: 24px;
    line-height: 18px;
    font-weight: 400;
    // color: #CACBCC;
    color: #455768;
    padding: 14px 16px;
    border-radius: 6px;
    width: 100%;
    height: 134px;
    font-family: Rubik !important;

    @media screen and (max-width: 600px) {
        font-size: 14px;
        height: 98px;
    }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 16px;
        height: 134px;
    }
}

.detailsTab__textArea--desktop textarea {
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    // color: #CACBCC;
    color: #455768;
    padding: 14px 16px;
    border-radius: 6px;
    width: 100%;
    height: 134px;
    font-family: Rubik !important;

    @media screen and (max-width: 600px) {
        font-size: 14px;
        height: 98px;
    }
}


// for mobile only

@media screen and (max-width: 450px) {
    .detailsTab__left--mobile {
        position: relative;
        top: -10px;
    }
}
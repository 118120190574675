.circularProgress__label {
    position: absolute;
    top: 12px;
    left: 7px;

    @media screen and (max-width: 450px) {
        font-size: 8px;
        font-weight: 500;
        line-height: 18;
        top: 5px;
    }
}


.MuiCircularProgress-root {
    width: 2px !important;
    height: 2px !important;
    direction: ltr;

    @media screen and (max-width: 600px) {
        width: 28px !important;
        height: 28px !important;
    }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        width: 42px !important;
        height: 42px !important;
    }
}

.MuiTypography-caption {
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: #6C7389;

    @media screen and (max-width: 450px) {
        font-size: 8px !important;
    }
}

.MuiCircularProgress-colorSecondary {
    color: 'red';
}
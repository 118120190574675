.deleteFile__confirmation {
    color: #455768;
    direction: ltr;
    display: flex;
    justify-content: end;
    font-size: 24px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 30px;


    @media screen and (max-width: 450px) {
        font-size: 12px;
    }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 16px;
    }
}

.delete__uploadInfo--margin {
    margin-bottom: 130px;

    @media screen and (max-width: 450px) {
        margin-bottom: 0px;
    }
}
@mixin displayFlex($dir: 'row', $justify :'unset', $align: 'unset') {
    display: flex;
    flex-direction: $dir;
    justify-content: $justify;
    align-items: $align
}

@mixin removeSpinnersFromNumberInput() {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
    
    &[type=number] {
        -moz-appearance:textfield; /* Firefox */
    }
}

@mixin errorMessage() {
    position: absolute;
    bottom: -15px;
    right:0;
    font-size: 12px;
    color: $warning;
}

@mixin appDropdown() {
    transform: scaleY(0);
    transition: transform 0.2s;
    transform-origin: top;
    position: absolute;
    top: calc(100% + 3px);
    right:0;
    padding: 11px 3px;

    width: 100%;
    min-width: 150px;
    border-radius: 5px;
    box-shadow: 0 7px 25px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    z-index: 10;
    max-height: 174px;
    overflow-y: scroll;
}

@mixin appDropdownActive() {
    transform: scaleY(1);
    transition: transform 0.2s;
}

@mixin appDropdownItem() {
    width: 100%;
    height: 30px;
    border-radius: 5px;
    @include displayFlex(row, flex-start, center);
    padding-right: 12px;
    background-color: #fff;
    cursor: pointer;
}

@mixin generalInputStyle($width: 100%) {
    width: $width;
    height: 40px;
    border-radius: 5px;
    border: solid 1px #e2e2e2;
    background-color: #f9f9f9;
    cursor: pointer;
}

@mixin generalButtonStyle($width: 100px) {
    width: 100px;
    width: $width;
    height: 36px;
    border-radius: 100px;
    border:none;
    font: inherit;
}

@mixin btnCancel(){
    background-color: $very-light-blue;
    margin-left:8px;
    font-size: 14px;
    color: $underlined-blue;
}

@mixin btnchange(){
    background-color: $underlined-blue;
    font-size: 14px;
    color: #ffffff;
}

@mixin oneLinerTextDots(){
    width:100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}


@mixin backToTodayBtn () {
    width: auto;
    height: 20px;
    border:none;
    border-radius: 3px;
    background-color: $neon-greenish;
    color:white;
    font:inherit;
    font-size: 14px;
    font-weight: 100;
    @include displayFlex(row,center,center);
    padding:0;
    position: absolute;
    right:5px;

    @include mediaMaxWidth(800px){
        position: absolute;
        top:-116px;
        right:97px;
        border-radius: 0;
        height: 56px;
        width: 56px;

        & > svg {
            color:black!important;
            height: 25px!important;
            width: 25px!important;
        }

        & > p {
            display: none;
        }
    }

    & > svg {
        color:white;
        height: 18px;
        width: 18px;
        margin-left:3px;
    }
}


@mixin mediaMaxWidth($size){
    @media screen and (max-width:$size) {
        @content;
    }
}

@mixin mediaMinWidth($size){
    @media screen and (min-width:$size) {
        @content;
    }
}

@mixin mediaMaxHeight($size){
    @media screen and (max-height:$size) {
        @content;
    }
}
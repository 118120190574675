.customerDetails__header {
  direction: ltr;
  width: 100%;
  height: 52px;
  background-color: #C5E1FA;
  padding: 0 16px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;

}

.customerDetails__header-2 {
  direction: ltr;
  width: 100%;
  height: 28px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
}

.customerDetails__header-2--tablet {
  height: 75px;
  border-radius: 12px;

  @media screen and (min-width: 601px) and (max-width: 1000px) {
    height: 52px;
  }
}

.customerDetails__header__tab {
  width: 100%;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  cursor: pointer;
}

.customerDetails__header__tab-2 {
  width: 92px;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #CACBCC;
}

.customerDetails__header__tab_value {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #0091FF;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
}

.customerDetails__header__tab_value span {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  font-family: Rubik !important;
}

.customerDetails__header__tab_value--tablet {
  width: 34px;
  height: 34px;
  border-radius: 50%;

  @media screen and (min-width: 601px) and (max-width: 1000px) {
    width: 24px;
    height: 24px;

  }
}

.customerDetails__header__tab_value--tablet span {
  color: #fff;
  font-size: 17px;
  line-height: 18px;
  font-weight: 500;
  font-family: Rubik !important;

  @media screen and (min-width: 601px) and (max-width: 1000px) {
    font-size: 12px;

  }
}

.customerDetails__header__tab_text {
  background-color: transparent;
  padding: 0px 10px;
}

.customerDetails__header__tab_text-2 {
  background-color: transparent;
  padding: 0px 5px;
}

.customerDetails__header__tab_text span {
  font-size: 16px;
  font-weight: 800;
  color: #0091FF;
  font-family: Rubik !important;
}

.customerDetails__header__tab_text--tablet span{
  font-size: 30px;
  font-weight: 500;
  font-family: Rubik !important;

  // line-height: 18px;
  @media screen and (min-width: 601px) and (max-width: 1000px) {
    font-size: 16px;
  }
}

.customerDetails__header__tab_text-2 span {
  font-size: 12px;
  font-weight: 800;
  font-family: Rubik !important;
  color: #455768;
}

.selectedTab {
  background-color: #fff;
  margin: 0px 16px 10px 16px;
  margin: auto !important;
  border-radius: 10px;
  padding: 5px 0;
}

.selectedTab-2 {
  background: linear-gradient(0deg, #0091FF, #0091FF), linear-gradient(0deg, rgba(0, 145, 255, 0.2), rgba(0, 145, 255, 0.2));
  color: #0065B1;
  border: 1px solid #0091FF
}

.selectedTab-2 span {
  color: #0065B1;
}
.accordionRow__main {
    border: 1px solid #CACBCC;
    border-radius: 10px;
    width: 100%;
    height: 62px;
    padding: 10px 24px 10px 24px;
    margin-top: 16px;
    direction: rtl;
    align-items: center;
    position: relative;
    background-color: #fff;

    @media screen and (max-width: 450px) {
        border-color: #C4E3FC;
        height: 52px;
        padding: 4px 16px 4px 10px;
        display: flex;
        align-items: center;
        background-color: #F2F7FB;
        margin-right: 10px;
        width: calc(100% - 10px) !important;
    }
}

.accordion__col-2 {
    width: 15%;

    @media screen and (max-width: 450px) {
        width: 20%;
    }
}

.accordion__col-3 {
    width: 25%;
}

.accordion__col-4 {
    width: 30%;

    @media screen and (max-width: 450px) {
        width: 80%;
    }
}

.accordion__col-5 {
    width: 50%;
}

.accordion__col-1 {
    width: 5%;
    justify-content: right;
}

.accordionRow__col {
    text-align: right;
}

.accordionRow__menuProgress {
    display: flex;
    justify-content: left;
    flex-direction: row;
    // width: 100%;
}

.accordionRow__col__content {
    display: flex;
    flex-direction: column;
}

.accordionRow__col__name span {
    background-color: #F2F5ED;
    color: #81B03E;
    font-size: 10px;
    font-weight: 800;
    text-align: right;
    display: flexbox;
    padding: 2px 10px;
    border-radius: 6px;
    font-family: Rubik !important;

    @media screen and (max-width: 450px) {
        font-size: 8px;
        padding: 4px 10px;
    }
}

.accordionRow__col__text {
    color: #455768;
    background-color: transparent;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;

    @media screen and (max-width: 450px) {
        font-size: 10px;
    }
}

.accordion__icons_container {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #F7F7F7;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: 450px) {
        width: 22px;
        height: 22px;
        border: 1px solid #C4E3FC;
    }
}

.accordion__menuIcon_container {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #F7F7F7;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: 450px) {
        width: 28px;
        height: 28px;
    }
}

.accordion__icon {
    color: #0091FF;
    font-size: 20px;

    @media screen and (max-width: 450px) {
        font-size: 16px !important;
    }
}

.accordion__menuIcon__icon {
    color: #0091FF;
    font-size: 20px;

    @media screen and (max-width: 450px) {
        font-size: 18px !important;
    }
}

.accordion__progress--alignment {
    position: absolute;
    left: 15px;
}


@media screen and (min-width: 451px) {
    .mobileView {
        display: none;
    }

    .desktopView {
        display: block;
    }

    .accordionRow__viewAllDetails {
        display: none;
    }
}

@media screen and (max-width: 450px) {
    .mobileView {
        display: block;
    }

    .desktopView {
        display: none;
    }

    .accordion__iconContainer__mobile {
        position: absolute;
        right: -11px;
        top: 13px;
    }

}


// viewAll the details on mobile view














// tablet view mode

.accordionRow__main--tablet {
    border: 2px solid #94C7F0;
    border-radius: 10px;
    margin-right: 6px;
    width: calc(100% - 7px) !important;
    height: 62px; // 106px;
    //  padding: 20px 24px;
    padding: 10px;
    margin-top: 16px;
    direction: rtl;
    // align-items: center;
    position: relative;
    background-color: #EBF6FF;


    @media screen and (max-width: 600px) {
        border: 1px solid #C4E3FC;
        height: 52px;
        padding: 4px 16px 4px 10px;
        display: flex;
        align-items: center;
        background-color: #F2F7FB;
    }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        height: 62px;
        // padding: 10px 24px 10px 24px;
        background-color: #EBF6FF;
    }

}

.accordion__col-2--tablet {
    width: 20%;
    display: flex;
    justify-content: left;
    align-items: center;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        width: 30%;
        // border: 1px solid orange;
    }
}

.accrodion__col-2--tablet-md {
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        position: absolute;
        left: 10px;
    }
}

.accordion__col-3--tablet {
    width: 25%;
}

.accordion__col-4--tablet {
    width: 65%;
    margin-right: 15px;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        width: 60%;
        margin-right: 5px;
    }
}


.accordion__col-5--tablet {
    width: 50%;
}

.accordionRow__viewAllDetails--tablet {
    display: flex;
    flex-direction: row;
}

.accordion__col-1--tablet {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: right;
}

.accordionRow__col--tablet {
    text-align: right;
}

.accordionRow__menuProgress--tablet {
    display: flex;
    justify-content: right;
    flex-direction: row;
    height: calc(62px - 20px);
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        justify-content: left;
    }

}

.accordionRow__menuProgress__alignment--tablet {
    // padding-top: 10px;
    position: relative !important;
    left: -16px;
    top: -30px;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        padding-top: 0px;
        position: relative;
        left: -16px;
    }
}

.accordionRow__col__content--tablet {
    display: flex;
    flex-direction: column;
}

.accordionRow--tablet-overflow {
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        max-width: 150px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

}

.accordionRow__col__name--tablet span {
    background-color: #F2F5ED;
    color: #81B03E;
    font-size: 10px; // 20px
    font-weight: 800;
    text-align: right;
    padding: 2px 10px; // 10px 16px;
    border-radius: 10px;
    font-family: Rubik !important;


    @media screen and (max-width: 600px) {
        font-size: 8px;
        padding: 4px 10px;
    }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 10px;
        padding: 2px 10px;
        border-radius: 6px;
    }
}
.accordion__text__control--desktop {
    display: inline-block;
    max-width: 202px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.accordion__text__control--tablet {
    display: inline-block;
    max-width: 135px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.accordion__text__control--mobile {
    display: inline-block;
    max-width: 170px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.accordionRow__col__text--tablet {
    color: #455768;
    background-color: transparent;
    font-size: 12px; // 24px
    font-weight: 400;
    line-height: 18px;
    margin-top: 0px; // 15px;

    @media screen and (max-width: 600px) {
        font-size: 10px;
    }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 12px;
        margin-top: 0px;
    }
}

.accordion__icons_container--tablet {
    width: 32px; // 52px;
    height: 32px; // 52px;
    border-radius: 50%;
    background-color: #FFFFFF;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        width: 32px;
        height: 32px;
    }
}

.accordion__menuIcon_container--tablet {
    width: 32px; // 52px;
    height: 32px; // 52px;
    border-radius: 50%;
    background-color: #FFFFFF;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        width: 32px;
        height: 32px;
    }
}

.accordion__icon--tablet {
    color: #0091FF;
    font-size: 20px; // 32px !important;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 20px !important;
    }
}

.accordion__menuIcon__icon--tablet {
    color: #0091FF;
    font-size: 20px;
}

.accordion__progress--mobileViewExpand{
    position: relative;
    top: -19px;
}


// for the angle arrow Icon
.accordion__nextRow{
    display: flex;
    flex-direction: row;
}
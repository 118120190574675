.processRow__main {
    width: 100%;
    height: 66px;
    padding: 12px 32px;
    background-color: #EBF5EA;
    border: 1px solid #B1D0AE;
    border-radius: 20px;
    box-shadow: 0px 0px 0px 0px #9696961A, 0px 3px 7px 0px #9696961A, 0px 14px 14px 0px #96969617, 0px 30px 18px 0px #9696960D, 0px 54px 22px 0px #96969603, 0px 85px 24px 0px #96969600;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px !important;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Rubik !important;

    @media screen and (max-width: 799px) {
        height: auto;
        flex-direction: column;
    }
}

.processRow__col {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;

    @media screen and (max-width: 799px) {
        flex-direction: column;
    }

}

.processRow__col-3 {
    width: 20%;

    @media screen and (max-width: 799px) {
        width: 100%;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
    }
}

.processRow__col-2 {
    width: 15%;

    @media screen and (max-width: 799px) {
        width: 100%;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
    }
}

.processRow__col-1 {
    width: 11.5%;

    @media screen and (max-width: 799px) {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-direction: row;
    }
}

.processRow__col-0 {
    width: 8%;
    position: relative;

    @media screen and (max-width: 799px) {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-direction: row;
    }
}


.processRow__col--heading {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #006DC0;
    width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Rubik !important;

    @media screen and (max-width: 1000px) {
        width: 50px;
    }
}

.processRow__col--value {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    font-family: Rubik !important;
}

.processRow__status {
    display: flex;
    justify-content: center;
    align-items: center;


    @media screen and (max-width: 799px) {
        display: none;
    }
}

.processRow__check-alignment {
    position: absolute;
    left: -15px;
    top: 0px;
}

.processRow__btn-alignment {
    position: absolute;
    top: 0px;
}

.processRow__progressBar-alignment {
    position: absolute;
    top: -25px;
    left: -10px;
}

.processRow__icon {
    font-size: 36px !important;
    color: #186315;
}

// color 
.color-455768 {
    color: #455768;
}

.color-0091FF {
    color: #0091FF;
}

.backgroundColor-F9F9F9 {
    background-color: #F9F9F9;
}

.borderColor-CACBCC {
    border-color: #CACBCC;
}


// mobile-view only
.process__heading--mobile {

    align-items: center;
    //  border: 1px solid red;
    margin-top: auto;

    @media screen and (max-width: 799px) {
        display: flex !important;

    }

    @media screen and (min-width: 800px) {
        display: none;
    }
}

.processRow__status--mobile {
    @media screen and (max-width: 799px) {
        display: inline-block;

    }

    @media screen and (min-width: 800px) {
        display: none;
    }
}
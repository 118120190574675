.year__section {
  height: 30px;
  background-color: #243748;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Assistant;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}

.calender__section::-webkit-scrollbar {
  height: 11px;
  width: 10px;
 
}

.calender__section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(177, 177, 177);
  border-radius: 10px;
  margin-right: 189px;
  
}

.calender__section::-webkit-scrollbar-thumb {
  background: #0091ff;
  border-radius: 10px;
 
}



body {
  // scrollbar-base-color: rgb(241, 237, 237);
  scrollbar-face-color: #0091ff;
  scrollbar-3dlight-color: rgb(255, 255, 255);
  scrollbar-highlight-color: rgb(243, 226, 226);
  scrollbar-track-color: rgb(219, 215, 215);
  scrollbar-arrow-color: rgb(219, 215, 215);
  scrollbar-shadow-color: #0091ff;
  -webkit-overflow-scrolling: touch;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
  overscroll-behavior-y: contain;
}

.calender__section {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow-x: auto;
  background-color: #f3f3f3;
  width: auto;
  background: linear-gradient(to left, white, white 189px, #f3f3f3 189px);
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */

}

.top__calender__container {
  display: flex;
  height: 61px;

  position: sticky;
  top: 0px;
  z-index: 90;

  .side__bar__titles {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #919ba3;
    border-left: 1px solid #919ba3;
    align-items: center;
    position: sticky;
    right: 0;
    z-index: 8;
    // height: 100%;
    background-color: white;

    .side__bar__buttons{
      position: fixed;
      bottom: 0px;
      right: 0px;
      background-color: #243748;
      width: 189px;
      height: 30px;
      color: white;
      display: none;
      justify-content: center;
      align-items: center;
      span{
        margin: 6px;
      }
    }
  }

  .weeks__holiday__bar {
    height: 61px;
    font-family: Rubik;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #919ba3;
    justify-content: center;
    text-align: center;
    padding-right: 15px;
    // margin-right: 188px;
    background-color: white;

    .weeks {
      height: 25px;
      display: inline-flex;
      text-align: center;

      .week {
        width: 130px;
        background-color: white;
        height: 25px;
        display: inline-flex;
        padding-top: 6px;
        text-align: center;
        justify-content: center;
        // margin-left: 3px;
        // margin-right: 1px;
        padding-left: 3px;
        padding-right: 7px;
      }
    }

    .weeks__dates {
      font-family: Rubik;
      font-size: 10px;
      font-weight: normal;
      height: 10px;

      .week__date {
        width: 130px;
        display: inline-flex;
        justify-content: center;
        // margin-left: 3px;
        // margin-right: 1px;
        padding-left: 3px;
        padding-right: 8px;
        color: #000000a3;
      }
    }

    .holidays {
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .holiday {
      height: 25px;
      width: 130px;
      align-items: center;
      padding-top: 6px;
      background-color: white;
      color: rgba(109, 212, 0, 0.3);
      // margin-left: 3px;
      // margin-right: 1px;
      padding-left: 3px;
      padding-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .week__list__title {
    display: flex;
    width: 188px;
    height: 30px;
    padding-right: 14px;
    padding-top: 8px;
    font-family: Rubik;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    color: #000000;
  }
  .holiday__list__title {
    display: flex;
    padding-right: 14px;
    font-family: Rubik;
    width: 188px;
    font-size: 14px;
    padding-top: 7px;
    font-weight: bold;
    text-align: right;
    color: #62ae00;
    border-top: 1px solid #e9ebec;
    // border-bottom: 1px solid #919ba3;
    padding-bottom: 9px;
  }
}

.projects__box {
  .project__lines {
    border-bottom: 1px solid #e9ebec;
  }
}

.main__box__container {
  text-align: center;
  background-color: #f3f3f3;
}

#current__week {
  background-color: rgba(36, 55, 72, 0.05);
}
.scroller {
  margin-right: 211px !important;
  position: fixed;
  bottom: 15px !important;
  width: 89.5% !important;
}

.rc-slider-handle {
  background-color: #0091ff !important;
  border-color: #0091ff !important;
  height: 18px !important;
  width: 18px !important;
}

.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #ffffff !important;
  height: 4px;
  margin-top: 2px !important;
  border-radius: 11px !important;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.MuiCircularProgress-root {
  width: 90px !important;
  height: 90px !important;
}

.empty_screen_container {
  display: flex;

  div {
    padding-top: 64px;
    font-size: 30px;
    margin: auto;
  }
}


.background_line{
  height: 100vh;
  position: fixed;
  background: #919ba3;
  width: 1px;
  margin-right: 188px;
}
@import '../abstracts/mixins.scss';
#main__step__container__is_disabled{
   pointer-events: none;
}


.step__inputs__container {
  display: flex;
//   align-items: flex-end;
  position: relative;

  & > span {
      position: absolute;
      top:-3px;
      right:0;
      @include displayFlex(row,unset,center);

      & > p {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #0091ff;
        margin-top: 5px;
      }
      & > p:nth-of-type(1) {
         width:167px;
         margin-right:8px;
      }

      & > p:nth-of-type(2) {
        width:70px;
        margin-right:30px;
      }

      & > p:nth-of-type(3) {
        margin-right:30px;
      }
  }

//   width: 460px;
  height: 45px;
  padding-top:5px;
  border-radius: 4px;
  background-color: #f9f9f9;
//   margin-right: 17px;
  margin-top: 6px;

  .fa-trash-alt {
    font-size: 14px;
    margin-left: 9px;
    cursor: pointer;
  }

  .fa-pencil-alt {
    font-size: 14px;
    cursor: pointer;
  }

  
 
}

.MuiInput-underline:before {
  border-bottom: 2px solid #dfdfdf !important;
}

label + .MuiInput-formControl {
  margin-top: 0px !important;
  font-family: "Rubik";
  font-size: 12px;
  text-align: right;
  color: #0091ff;
}

.MuiTypography-colorTextSecondary {
  color: rgba(0, 0, 0, 0.54);
  opacity: 0.3;
  font-family: Rubik;
  font-size: 16px;
  margin-top: 8px;
}

.step__select__input {
  margin-right: 9px;
  margin-left: 10px;
}

.step__select__input__disabled{
  margin-right: 9px;
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.38);
  pointer-events: none;
}
// .amount__input {
//   margin-left: 29px;
// }

.fa-check {
  color: #0091ff;
  font-size: 14px;
  margin-left: 9px;
  cursor: pointer;
}

.fa-times {
  font-size: 14px;
  cursor: pointer;
}

.step__input__icons {
//   margin-top: 11px;
//   display: flex;
    padding:10px;
    cursor: pointer;
    z-index: 100;
 
}

.main__step__container{
  height: 51px;

  .validate__message{
    font-family: Rubik;
      color: red;
      font-size: 12px !important;
      margin-right: 20px;
      margin-top: 40px;
  }

  .validate__message__disabled{
    display: none;
  }
}

